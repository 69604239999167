import { Container } from './styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { NavHashLink, HashLink } from 'react-router-hash-link';
import { useState, useEffect } from 'react';

export function Header() {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    let html = document.getElementsByTagName('html')[0];
    html.classList.add('dark'); // Set default theme to light
  }, []);

  function toggleTheme() {
    let html = document.getElementsByTagName('html')[0];
    if (html.classList.contains('light')) {
      html.classList.remove('light');
      html.classList.add('dark');
    } else {
      html.classList.remove('dark');
      html.classList.add('light');
    }
  }

  function closeMenu() {
    setActive(false);
  }

  return (
    <Container className="header-fixed">
      <Router>
        <nav className={isActive ? 'active' : ''}>
          <NavHashLink smooth to="#home" onClick={closeMenu}>
            Home
          </NavHashLink>
          <NavHashLink smooth to="#about" onClick={closeMenu}>
            About me
          </NavHashLink>
          <NavHashLink smooth to="#project" onClick={closeMenu}>
            Projects
          </NavHashLink>
          <NavHashLink smooth to="#events" onClick={closeMenu}>
            Contests
          </NavHashLink>
          <NavHashLink smooth to="#contact" onClick={closeMenu}>
            Contact
          </NavHashLink>
          {/* Add the Contests button here */}
          
        </nav>
        <div
          aria-expanded={isActive ? 'true' : 'false'}
          aria-haspopup="true"
          aria-label={isActive ? 'Close menu' : 'Open menu'}
          className={isActive ? 'menu active' : 'menu'}
          onClick={() => {
            setActive(!isActive);
          }}
        ></div>
        {!isActive && (
          <>
            <input
              onChange={toggleTheme}
              className="container_toggle"
              type="checkbox"
              id="switch"
              name="mode"
            />
            <label htmlFor="switch">Toggle</label>
          </>
        )}
        {/*<HashLink smooth to="#home" className="logo">
          <span>{"<farith/>"}</span>
        </HashLink>*/}
      </Router>
    </Container>
  );
}
