import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

export function Project() {
  // Define an array of project data containing project details and technologies
  const projects = [
    {
      title: "Advanced Face Recognition System",
      description: "Developed an advanced face recognition solution for a japanese company, enhancing security and access control. Employed advanced algorithms and machine learning to create a robust model, seamlessly integrated with existing infrastructure while prioritizing privacy measures",
      technologies: ["Python Flask", "DLIB", "OpenCV", "REST API"]
    },
    {
      title: "AI powered Supervision System (In progress)",
      description: "Developing an advanced AI-driven supervision system to monitor worker activity across the company's manufacturing and assembly line operations. Leveraging sophisticated algorithms, the system tracks and analyzes worker actions, generating comprehensive reports to optimize processes and ensure operational efficiency.",
      technologies: ["Python", "OpenCV", "LSTM", "Tensorflow"]
    },
    {
      title: "EWIN - LLM Chat Application",
      description: "Developed a sophisticated Large Language Model (LLM) chat application named EWIN. This application integrates customized versions of Llama3, Mistral, and Gemma to cater to diverse needs such as coding assistance, educational support, and engaging fun chats. The system ensures seamless interaction and delivers high-quality responses tailored to user requirements.",
      technologies: ["Python Flask", "React Native","MongoDB","Ollama"]
    },
    {
      title: "UNIT_ED - Holistic AI-Driven Learning Platform",
      description: "Developed a comprehensive web-based learning system powered by advanced AI algorithms. Offering interactive 3D models, diverse learning resources, and seamless integration of student data, the platform empowers self-paced learning. Additionally, discreetly embedded evaluative chatbot functionality provides personalized guidance and feedback, enhancing student engagement and learning outcomes.",
      technologies: [ "React", "Node.js","MongoDB","Ollama","Sketchfab"]
    },
    {
      title: "Meva - Fish Quality Assessment App",
      description: "Engineered an Android app leveraging Deep Convolutional Neural Network (DCNN) for precise fish quality detection. Through cutting-edge image processing, users receive instant insights on fish freshness and quality, empowering fisheries management and ensuring consumers access top-tier seafood. Seamless integration with mobile cameras and specialized hardware ensures real-time assessment.",
      technologies: ["React Native","Java", "DCNN", "Node.js", "Firebase"]
    },
    {
      title: "MDBMS - Data centralization system",
      description: "Engineered a robust Mechatronics Department Database Management System (MDBMS) to streamline accreditation data collection. Compliant with National Board of Accreditation (NBA) and National Assessment and Accreditation Council (NAAC) standards, this system centralizes student and faculty data, ensuring transparency and informed decision-making for departmental enhancements.",
      technologies: ["React", "Node.js", "MongoDB"]
    },
    {
      title: "SafeMine - IoT Safety Solution for Coal Miners",
      description: "Developed a cutting-edge IoT system, to ensure safety in coal mining. Constantly monitoring worker health and environmental conditions, it triggers immediate alerts in emergencies. This solution sets new standards for safety protocols, ensuring swift responses and mitigating risks in coal mining operations.",
      technologies: ["Android Studio", "Java", "Arduino"] 
    }
  ];

  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">
        {projects.map((project, index) => (
          <ScrollAnimation key={index} animateIn="fadeInUp">
            <div className="project">
              <header>
                <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#663399" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg> 
              </header>
              <div className="body">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
              <footer>
                <ul className="tech-list">
                  {project.technologies.map((tech, i) => (
                    <li key={i}>{tech}</li>
                  ))}
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
